
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
        <el-row>
            <el-col :span="8">
              <span class="fontText">所属站点：</span> 
              <span class="data">{{formModel.stationName}}</span>
            </el-col>
            <el-col :span="8">
              <span class="fontText">桩号：</span> 
              <span class="data">{{formModel.deviceNo}}</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">桩名：</span> 
                <span class="data">{{formModel.deviceName}}</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
              <span class="fontText">订单创建时间：</span> 
              <span class="data">{{formModel.createTime}}</span>
            </el-col>
            <el-col :span="8">
              <span class="fontText">充电开始时间：</span> 
              <span class="data">{{formModel.startTime}}</span>
            </el-col>
            <el-col :span="8">
              <span class="fontText">充电结束时间：</span> 
              <span class="data">{{formModel.endTime}}</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
                <span class="fontText">预计充电时长：</span> 
                <span class="data">{{formModel.estimateMinute}}分钟</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">充电功率：</span> 
                <span class="data">{{formModel.chargingPower}}瓦</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">估算金额：</span> 
                <span class="data">{{formModel.estimateFee}}元</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
                <span class="fontText">实际充电时长：</span> 
                <span class="data">{{formModel.chargingMinute}}分钟</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">单价：</span> 
                <span class="data">{{formModel.price}}元/小时</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">实付金额：</span> 
                <span class="data">{{formModel.actualFee}}元</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
                <span class="fontText">退费记录：</span> 
                <span class="data">{{formModel.refundStatus}}</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">退款时间：</span> 
                <span class="data" v-if="formModel.refundFee">{{formModel.endTime}}</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">退款金额：</span> 
                <span class="data">{{formModel.refundFee}}元</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" >
            <el-col :span="8">
                <span class="fontText">充电模式：</span> 
                <span class="data">{{formModel.templateName}}</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">预估电量：</span> 
                <span class="data">{{formModel.electricQuantity/10000}}度</span>
            </el-col>
            <el-col :span="8">
                <span class="fontText">结束原因：</span>
                <span class="data">{{formModel.remark}}</span>
            </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-container>
              <el-aside width="150px">
                <el-image
                  style="width: 150px; height: 150px"
                  :src="formModel.headImg">
                </el-image>
              </el-aside>
              <el-main>
                  <el-row >
                    <el-col :span="14">
                      <span class="fontText">用户昵称：</span> 
                      <span class="data">{{formModel.openId}}</span>
                    </el-col>
                    <el-col :span="10">
                      <span class="fontText">联系电话：</span> 
                      <span class="data">{{formModel.phone}}</span>
                    </el-col>
                </el-row>
                  <el-row>
                    <el-col :span="14">
                      <span class="fontText">注册时间：</span> 
                      <span class="data">{{formModel.regUserCreateTime}}</span>
                    </el-col>
                </el-row>
              </el-main>
            </el-container>
          </el-col>
        </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import chargingRecordApi from "@/api/base/chargingRecord";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            deviceNo: [
                { required: true, message: "桩号不能为空", trigger: "blur" }
            ],
            name: [
                { required: true, message: "桩名不能为空", trigger: "blur" }
            ],
            templateId:[
              {required: true, message: "收费模式不能为空", trigger: "blur" }
            ]
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        templateId:"",
        priceList:[],
      },
      templateList: [],
      defaultPriceList:[],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.priceList = self.defaultPriceList;

            if (id == null || id.length == 0) {
              return chargingRecordApi.add(self.formModel);
            } else {
              return chargingRecordApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  created() {
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return chargingRecordApi.create();
      } else {
        return chargingRecordApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data; 
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
  margin-left: 20px;
}
.data{
    margin-left: 10px;
    font-weight:bold;
}
.el-row {
margin-bottom: 20px;
}
.el-col {
border-radius: 4px;
}
.grid-content {
border-radius: 4px;
min-height: 36px;
}
.title1{
    margin-bottom: 40px;
}
.title2{
    margin-top: 40px;
    margin-bottom: 40px;
}
</style>