<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">充电桩管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/charging/chargingRecord">充电记录</a>
      </el-breadcrumb-item>
    </el-breadcrumb> 
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="所属站点" prop="stationId">
        <el-select-tree
          size="mini"
          :props="props"
          :options="chargingStationResult"
          v-model="queryModel.stationId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="桩号" prop="deviceNo">
        <el-input type="text" size="mini" v-model="queryModel.deviceNo"></el-input>
      </el-form-item>
      <el-form-item label="充电时间范围" prop="rangeTime">
        <el-date-picker
        size="mini"
        v-model="queryModel.rangeTime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm"
        format="yyyy-MM-dd HH:mm">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="时间选择" prop="name">
        <el-select v-model="queryModel.dateSelect" clearable placeholder="请选择" size="mini">
            <el-option
            v-for="item in dateSelectList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="意外停电" prop="isPowerCut">
        <el-select v-model="queryModel.isPowerCut" clearable placeholder="请选择" size="mini">
            <el-option
            v-for="item in isPowerCutList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户电话" prop="phone">
        <el-input type="text" size="mini" v-model="queryModel.phone"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            @click="exportXls"
        >导出数据</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      style="min-height:400px;"
      :height="tableHeight"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column  prop="headImg"  label="头像"  fixed="left" >
        <template slot-scope="{ row }">
          <a :href="row.headImg" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.headImg +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="用户电话"
        width="150"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="stationName"
        label="所属站点"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="deviceNo"
        label="桩号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="deviceName"
        label="桩名"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="channelNo"
        label="通道"
      ></el-table-column>
      <el-table-column
        prop="startTime"
        label="充电时间"
        width="170"
      ></el-table-column>
     <el-table-column
        prop="endTime"
        label="结束时间"
        width="170"
      ></el-table-column>
      <el-table-column
        prop="estimateMinute"
        label="预计充电分钟"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="estimateFee"
        label="估算金额(元)"
      ></el-table-column>
      <el-table-column
        prop="chargingMinute"
        label="实际充电分钟"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="roundingMinute"
        label="超时凑整（分钟）"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="单价（元/小时）"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="actualFee"
        label="实付金额(元)"
      ></el-table-column>
      <el-table-column
        prop="electricQuantity"
        label="总电量(度)"
      >
        <template slot-scope="{row}">
          {{row.electricQuantity/10000}}
        </template>
      </el-table-column>
      <el-table-column
        prop="refundStatus"
        label="退款记录"
      ></el-table-column>
      <el-table-column
        prop="templateName"
        label="充电模式"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="chargingPower"
        label="充电功率(瓦)"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="结束原因"
        width="300"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="170"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="100">
        <template slot-scope="{row}">
          <el-link type="primary" :underline="false" @click="handleEdit(row)">查看详情</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <chargingRecord-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></chargingRecord-detail>
  </div>
</template>
<script>
import chargingRecordDetail from "./chargingRecord-detail";
import chargingRecordApi from "@/api/base/chargingRecord";
import chargingStationApi from "@/api/base/chargingStation";
import SelectTree from "@/components/SelectTree";
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "chargingChargingRecordList",
  data() {
    var self = this;

    return {
      queryModel: {
        deviceNo: "",
        stationId: "",
        dateSelect:"",
        isPowerCut:"",
        rangeTime:"",
        phone:"",
      },
      dateSelectList:[{
          label:"当天",
          value:"1",
      },
      {
          label:"当月",
          value:"2"
      },
      {
          label:"上月",
          value:"3"
          
      }],
      isPowerCutList:[{
          label:"否",
          value:"0",
      },
      {
          label:"是",
          value:"1",
      }],
      chargingStationResult:[],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showModal2: false,
      modalTitle2: "",
      showModal3: false,
      modalTitle3: "",
      businessKey: "",
      selectedRow: {},
      tableHeight: 400
    };
  },
  methods: {
    adjustTableHeight() {
      var self = this;
      self.tableHeight = window.innerHeight - self.$refs.formTable.$el.offsetTop - 110;

      if(self.tableHeight<400){
        self.tableHeight = 400;
      }
    },
    handleBindClass(row) {
      this.selectedRow = row;
      this.showBindModal = true;
    },
    handleCloseLive() {
      if(this.player!=null){
        this.player.pause();
      }
    },
    exportXls() {
        this.changePage(1,true);
    },
    changePage(pageIndex,exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if(self.queryModel.stationId!=null){
        formData.append("stationId", self.queryModel.stationId);
      }
      else{
        formData.append("stationId", "");
      }

      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("rangeTime", self.queryModel.rangeTime);
      formData.append("dateSelect", self.queryModel.dateSelect);
      formData.append("isPowerCut", self.queryModel.isPowerCut);
      formData.append("phone", self.queryModel.phone);

      formData.append("isRefund", "0");

      if(exportFlag!=null){
          formData.append("exportFlag", exportFlag);
          formData.append("pageSize", 10000);
      }
      else{
          formData.append("pageSize", self.pageSize);
      }


      chargingRecordApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;
            var jsonData = response.data;
            if(jsonData.result) {
                if(exportFlag){
                  //导出
                  self.$message({
                      showClose: true,
                      type: "success",
                      message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                      dangerouslyUseHTMLString: true,
                      duration: 30000
                  });
                }
                else{
                  //分页查看
                  var page = jsonData.data;

                  self.tableData = page.data;
                  self.totalPages = page.totalPages;
                  self.totalElements = page.recordsTotal;
                  
                  self.adjustTableHeight();
                }
            }
            else{
                self.$message.warning(jsonData.message);
            }
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "查看详情";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          chargingRecordApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        chargingRecordApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  created() {
    var self = this;
    chargingStationApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.chargingStationResult = jsonData.data;
        }
      }
    });
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "chargingRecord-detail": chargingRecordDetail,
     "el-select-tree": SelectTree
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.capture-image{
  width:160px;
  height: 90px;
  object-fit: container;
}
</style>